let origin = "https://future.zhuneng.cn";
//积分
const pointsUrl = `/gateway/hc-accumulated/pointuser/getPointUserDetail`;
//积分记录
const pointsList = `/gateway/hc-accumulated/pointrecord/getPointRecordList`;
//获取积分
const getPoints = `/gateway/hc-accumulated/pointProcess/doPointProcess`;
//积分排行榜
const pointRankListUrl = `/gateway/hc-accumulated/pointuser/getPointUserRankingList`;

// 志愿积分详情
const volunteerPointDetailsUrl = `/gateway/hc-serve/miniapi/volunteer/volunteerPointDetails`;
// 志愿积分排名列表
const volunteerPointRankingListUrl = `/gateway/hc-serve/miniapi/volunteer/volunteerPointRankingList`;
// 获取SceneParam参数
const getSceneParamUrl = `/gateway/hc-mini/qrcode/getSceneParam`;
// 志愿者签到接口
const volunteerSignInUrl = `/gateway/hc-serve/miniapi/volunteer/volunteerSignIn`;
export {
  pointsUrl,
  pointsList,
  getPoints,
  pointRankListUrl,
  volunteerPointDetailsUrl,
  volunteerPointRankingListUrl,
  getSceneParamUrl,
  volunteerSignInUrl,
};
